import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import FileCopy from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import ShowGraphIcon from '@material-ui/icons/Timeline'
import Tooltip from '@material-ui/core/Tooltip'

import { formatDate } from '../utils/formatDate'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'
import { getPortfolios, getPortfoliosQuery } from '../store/selectors/portfolios.selectors'
import { services } from '../store/feathers'
import { showModal } from '../store/modal'

const getNextName = (records, name) => {
  // First escape any special character in name string to prevent it from being parsed as regex command
  const escapedName = name.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&')
  const regex = new RegExp(`^${escapedName} \\(copy (\\d+)\\)$`)
  let maxNum = 0

  records.forEach((rec) => {
    const found = rec.name.match(regex)
    if (found && Number(found[1]) > maxNum) {
      maxNum = Number(found[1])
    }
  })
  return `${name} (copy ${maxNum + 1})`
}

const PortfoliosActions = (props) => {
  const { record } = props
  const hasAdminRights = useSelector(isAdmin)
  const email = useSelector(getEmail)
  return (
    <>
      <Tooltip title="Show Curve">
        <span>
          <IconButton
            aria-label="Show Curve"
            disabled={!(hasAdminRights || email === record.creator || record.isPublic)}
            component={Link}
            to={{ pathname: `/portfolios/${record._id}/show-curve`, returnTo: '/portfolios' }}
          >
            <ShowGraphIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="View Portfolio">
        <span>
          <IconButton
            aria-label="View Portfolio"
            disabled={!(hasAdminRights || email === record.creator || record.isPublic)}
            component={Link}
            to={`/portfolios/${record._id}`}
          >
            <AssignmentIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const PortfoliosEditActions = (props) => {
  const { record } = props
  const dispatch = useDispatch()
  const portfolios = useSelector(getPortfolios)
  const portfolioQuery = useSelector(getPortfoliosQuery)

  const onDelete = () => {
    dispatch(
      showModal({
        title: 'Delete',
        content: (
          <>
            Are you sure you want to delete {record.name ? <b>{record.name} </b> : 'selected'}
            portfolio?
          </>
        ),
        confirmLabel: 'Delete',
        onConfirm: () => {
          dispatch(services.portfolios.remove(record._id)).then(() => {
            // On record delete reset the pagination (skip) to 0
            dispatch(services.portfolios.find({ query: { ...portfolioQuery.query, $skip: 0 } }))
          })
        },
      }),
    )
  }

  const onClone = () => {
    const { createdAt, _id, updatedAt, name, ...data } = record
    const clonedName = getNextName(portfolios, name)

    dispatch(
      showModal({
        title: 'Clone Portfolio',
        content: (
          <>
            Are you sure you want to clone {record.name ? <b>{record.name} </b> : 'selected'}
            portfolio?
          </>
        ),
        confirmLabel: 'Clone',
        onConfirm: () => {
          dispatch(services.portfolios.create({ ...data, name: clonedName })).then(() => {
            dispatch(services.portfolios.find(portfolioQuery))
          })
        },
      }),
    )
  }

  return (
    <>
      <Tooltip title="Clone Portfolio">
        <IconButton aria-label="Clone Portfolio" onClick={onClone}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Portfolio">
        <IconButton aria-label="Delete Portfolio" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export const portfoliosColumns = [
  {
    label: '',
    field: 'actions-link',
    width: 1,
    nowrap: true,
    render: (record) => {
      return <PortfoliosActions record={record} />
    },
  },
  {
    label: 'Name',
    field: 'name',
    hasFilter: true,
    isSortable: true,
    render: (record) => {
      return (
        <Link to={{ pathname: `/portfolios/${record._id}/show-curve`, returnTo: '/portfolios' }}>{record.name}</Link>
      )
    },
  },
  { label: 'Creator', field: 'creator', hasFilter: true, isSortable: true },
  {
    label: 'Description',
    field: 'description',
    hasFilter: true,
    isSortable: true,
    width: 300,
    render: (record) => {
      return <div style={{ whiteSpace: 'break-spaces' }}>{record.description}</div>
    },
  },
  {
    label: 'Created',
    field: 'createdAt',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    render: (record) => {
      return formatDate(record.createdAt)
    },
  },
  {
    label: 'Updated',
    field: 'updatedAt',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    render: (record) => {
      return formatDate(record.updatedAt)
    },
  },
  {
    label: 'Public',
    field: 'isPublic',
    hasFilter: true,
    isSortable: true,
    type: 'boolean',
    render: (record) => {
      return record.isPublic ? <CheckIcon /> : null
    },
  },
  {
    label: '',
    field: 'actions',
    width: 1,
    nowrap: true,
    render: (record) => {
      return <PortfoliosEditActions record={record} />
    },
  },
]

export const portfolioColumns = (prepend, append) => [
  ...(prepend ? [prepend] : []),
  {
    label: 'Date',
    field: 'date',
    hasFilter: true,
    isSortable: true,
    type: 'date',
    nowrap: true,
    render: (record) => {
      return !record.date || !isNaN(record.date) ? 'No Date Stamp' : formatDate(record.date)
    },
  },
  {
    label: 'Sample Info',
    field: 'meta.sampleInfo1Value',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.sampleInfo1Value || 'undefined',
  },
  {
    label: 'Operator',
    field: 'meta.operator',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta?.operator || 'undefined',
  },
  {
    label: 'Instrument ID',
    field: 'instrumentId',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.instrumentId,
  },
  {
    label: 'Cartridge S/N',
    field: 'meta.cartridgeSerialNumber',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.cartridgeSerialNumber,
  },
  {
    label: 'Assay Name',
    field: 'meta.assayShortName',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    render: (record) => record.meta.assay?.assayShortName ?? '',
  },
  {
    label: 'Assay ID',
    field: 'meta.assayId',
    type: 'string',
    hasFilter: true,
    isSortable: true,
    calcFnc: (record) =>
      record.meta.assayId
        ? record.meta.assayId + (record.meta.assayRevision ? parseFloat(`0.${record.meta.assayRevision}`) : 0)
        : 'Unknown',
    render: (record) =>
      record.meta.assayId
        ? `${record.meta.assayId}${record.meta.assayRevision ? `.${record.meta.assayRevision}` : ''}`
        : 'Unknown',
  },
  {
    label: 'Annotation',
    field: 'annotation',
    type: 'string',
    hasFilter: true,
    isSortable: true,
  },
  {
    label: '',
    field: 'annotation-action',
    cellStyle: { width: '70px' },
    headerStyle: { width: '70px' },
  },
  ...(append ? [append] : []),
]
