import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Prompt } from 'react-router'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AddIcon from '@material-ui/icons/AddBox'
import AssignmentIcon from '@material-ui/icons/Assignment'

import { defaults, Line } from 'react-chartjs-2'

import { classes as cl } from '../utils/classes'
import { download } from '../utils/dataExport'
// import { createSvg, download } from '../utils/dataExport'
import { extractData, getConfig, emptyPortfolioData } from '../utils/chartConfig'
import { curveMetricsPageStyles } from '../utils/styles/curveMetricsPageStyles'
import { deepClone } from '../utils/object'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'
import { hideOverlay, showOverlay } from '../store/overlay'
import { isNumber } from '../utils/number/isNumber'
import { services } from '../store/feathers'
import api from '../utils/api'
import AutoQuantTable from '../components/AutoQuantTable'
import SelectionGrid from '../components/SelectionGrid'
import ColorsDialog from '../components/ColorsDialog'
import { deepMerge } from '../utils/object/deepMerge'
import { fastDeepEqual } from '../utils/object/fastDeepEqual'
import OnBeforeUnload from '../components/OnBeforeUnload'
import Page from '../components/Page'
import { slugify } from '../utils/string'
import { isPending } from '@reduxjs/toolkit'

const unSavedChangesMessage = 'There are unsaved changes, are you sure you want to leave?'

/**
 * scalesAreEqual - Function used to compare two scale object
 *
 * @param {*} next - Next object containing scale properties
 * @param {*} current - Current object containing scale properties
 * @returns {boolean} - Returns true if objects are equal, otherwise false
 */
const scalesAreEqual = (next, current) => {
  // Check if autoScale values are equal
  if (next.autoScaleX === current.autoScaleX && next.autoScaleY === current.autoScaleY) {
    // Now since we know autoScale values aren't changed, we check values only if they are set
    // otherwise it doesn't matter since they will be overwritten once user uncheck the autoScale
    if (!next.autoScaleX && (next.xMin !== current.xMin || next.xMax !== current.xMax)) {
      return false
    }
    if (!next.autoScaleY && (next.yMin !== current.yMin || next.yMax !== current.yMax)) {
      return false
    }
    return true
  }
  // autoScale are different, return false
  return false
}

const compareChanges = (localPortfolio, remotePortfolio) => {
  if (!remotePortfolio) return false
  const { scale: localScale, ...local } = localPortfolio
  const { scale: remoteScale, ...remote } = remotePortfolio
  return !(scalesAreEqual(localScale, remoteScale) && fastDeepEqual(local, remote))
}

// Update chart.js default settings
deepMerge(defaults, {
  global: {
    animation: false,
    elements: {
      borderColor: '#F85F73',
      line: {
        fill: false,
      },
    },
  },
})

class CurveMetrics extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fetchedAt !== prevState.fetchedAt) {
      return { localPortfolio: deepClone(nextProps.portfolio), fetchedAt: nextProps.fetchedAt }
    }

    return null
  }

  state = {
    localPortfolio: null,
    colorDialogIsOpen: false,
  }

  chartRef = createRef()

  componentDidMount() {
    this.props.loadPortfolio()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.localPortfolio?.legendVisible !== this.state.localPortfolio?.legendVisible &&
      this.chartRef?.current?.chartInstance
    ) {
      this.chartRef.current.chartInstance.resize()
    }
    // Check autoScale values after tests were changed
    if (prevState?.localPortfolio?.tests !== this?.state?.localPortfolio?.tests) {
      const scale = {}
      // When selected tests changes, update the autoScale if its set to manual
      if (this.state.localPortfolio.scale.autoScaleX) {
        Object.assign(scale, this.getChartScale('autoScaleX', this.state.localPortfolio.scale.autoScaleX))
      }
      if (this.state.localPortfolio.scale.autoScaleY) {
        Object.assign(scale, this.getChartScale('autoScaleY', this.state.localPortfolio.scale.autoScaleY))
      }
      // If there are any updates merge them with current portfolio values
      if (Object.keys(scale)) {
        this.updatePortfolio('scale', scale)
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const change = fastDeepEqual(nextState, this.state)
    // Re-render only when fetchedAt changes (new data has arrived) or the local state has changes
    return nextProps.fetchedAt !== this.props.fetchedAt || !change
  }

  updatePortfolio = (prop, val) => {
    // If changed property is inside a nested object, clone it and merge the changes
    // but skip arrays - they should be overwritten so we can remove elements
    if (!Array.isArray(this.state.localPortfolio[prop]) && typeof this.state.localPortfolio[prop] === 'object') {
      this.setState({
        localPortfolio: { ...this.state.localPortfolio, [prop]: { ...this.state.localPortfolio[prop], ...val } },
      })
    } else {
      this.setState({ localPortfolio: { ...this.state.localPortfolio, [prop]: val } })
    }
  }

  savePortfolio = async (evt, colorConfig) => {
    const update = deepClone(this.state.localPortfolio)
    if (colorConfig) {
      update.colorConfig = colorConfig
    }
    // Show saving overlay
    this.props.dispatch(showOverlay())
    await api.service('portfolios').patch(this.props.portfolioId, update)
    this.props.dispatch(services.portfolios.get(this.props.portfolioId, { query: { withData: true } })).then(() => {
      setTimeout(() => {
        this.props.dispatch(hideOverlay())
      }, 800)
    })
  }

  getChartScale = (name, value) => {
    const update = {}
    const scale = this.state.localPortfolio.scale
    const instance = this.chartRef.current.chartInstance

    if (name === 'autoScaleY' && value) {
      // Re-evaluate min/max values when AutoScale is toggled on
      if (scale.yMin !== instance.scales['y-axis-0'].start) update.yMin = instance.scales['y-axis-0'].start
      if (scale.yMax !== instance.scales['y-axis-0'].end) update.yMax = instance.scales['y-axis-0'].end
    } else if (name === 'autoScaleX' && value) {
      if (scale.xMin !== instance.scales['x-axis-0'].min && instance.scales['x-axis-0'].min !== undefined)
        update.xMin = instance.scales['x-axis-0'].min
      if (scale.xMax !== instance.scales['x-axis-0'].max && instance.scales['x-axis-0'].max !== undefined)
        update.xMax = instance.scales['x-axis-0'].max
    }
    return update
  }

  handleScaleChange = ({ target: { name, value }, type }) => {
    const update = this.getChartScale(name, value)
    this.updatePortfolio('scale', { [name]: isNumber(value) ? Number(value) | 0 : value, ...update })
  }

  handleThresholdChange = (event) => {
    let value = parseFloat(event.target.value)
    if (event.target.value === '') value = 0.0

    if (Number.isNaN(value)) return

    this.updatePortfolio(event.target.id, value)
  }

  handleSubtractedChange = () => {
    this.updatePortfolio('notSubtracted', !this.state.localPortfolio.notSubtracted)
  }

  closeColorDialog = (data) => {
    if (data) {
      // Get other color type configs and merge them with currently saving object
      // Make sure colorConfig exists in the current portfolio - needed for backward compatibility
      const { colorConfig = {} } = this.state.localPortfolio
      if (colorConfig[data.type]) delete colorConfig[data.type]
      this.savePortfolio(null, { ...colorConfig, ...data })
    }
    this.setState({ colorDialogIsOpen: false })
  }

  render() {
    const { classes, email, hasAdminRights, portfolio, portfolioId, location, autoQuantData, xTicks } = this.props
    const { localPortfolio, colorDialogIsOpen } = this.state
    const {
      name: portfolioName,
      curveLegendVisible = true,
      legendVisible = true,
      masks,
      tests,
      testRecords,
      quality,
      intensity,
      performance,
      notSubtracted,
      scale,
    } = localPortfolio || emptyPortfolioData

    const returnTo = location?.returnTo || `/portfolios/${portfolio?._id}`
    const yProp = notSubtracted ? 'y' : 'yAdj'
    // const [xTicks, autoQuantData] = extractData(localPortfolio, yProp)
    const saveDisabled = !(hasAdminRights || email === portfolio?.creator || portfolio?.isPublic === 'write')
    const config = getConfig(
      scale,
      xTicks,
      yProp,
      tests,
      testRecords,
      // use the last previous saved portfolio data
      portfolio?.colorConfig,
      curveLegendVisible,
      portfolioName,
    )

    const unSavedChanges = compareChanges(localPortfolio, portfolio)

    return (
      <>
        <OnBeforeUnload message={unSavedChanges && unSavedChangesMessage} />
        <Prompt when={unSavedChanges} message={unSavedChangesMessage} />
        <Page
          loadingSelector={(state) => state.portfolios.get === null}
          title={portfolio?.name || ''}
          backTo={{ url: returnTo }}
          actions={[
            {
              title: 'View Portfolio',
              icon: <AssignmentIcon />,
              onClick: () => this.props.history.push(`/portfolios/${portfolioId}`),
            },
            ...(hasAdminRights || email === localPortfolio?.creator || localPortfolio?.isPublic === 'write'
              ? [
                  {
                    title: 'Add Tests',
                    icon: <AddIcon />,
                    onClick: () => this.props.history.push(`/portfolios/${portfolioId}/add-tests`),
                  },
                ]
              : []),
          ]}
        >
          <Paper className={classes.content}>
            <Box display="flex" flexDirection="row">
              {legendVisible && (
                <Box display="flex" flexDirection="column" position="relative" minWidth="650px">
                  <SelectionGrid
                    tests={[...testRecords]}
                    masks={[...masks]}
                    setMasks={(masks) => this.updatePortfolio('masks', masks)}
                    testsData={deepClone(tests)}
                    setTestsData={(tests) => this.updatePortfolio('tests', tests)}
                  />

                  <Button
                    variant="outlined"
                    className={classes.savePortfolioButton}
                    onClick={this.savePortfolio}
                    disabled={saveDisabled}
                  >
                    Save to portfolio
                  </Button>
                </Box>
              )}
              <div className={classes.curve}>
                <div>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <FormControlLabel
                      className={classes.toggleLegend}
                      control={
                        <Switch
                          checked={legendVisible}
                          color="primary"
                          onChange={() => this.updatePortfolio('legendVisible', !legendVisible)}
                        />
                      }
                      label="Selection grid"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={classes.toggleLegend}
                      control={
                        <Switch
                          checked={curveLegendVisible}
                          color="primary"
                          onChange={() => this.updatePortfolio('curveLegendVisible', !curveLegendVisible)}
                        />
                      }
                      label="Curve legend"
                      labelPlacement="end"
                    />
                    {!legendVisible && (
                      <Button
                        variant="outlined"
                        onClick={this.savePortfolio}
                        className={classes.inlineSaveBtn}
                        disabled={saveDisabled}
                      >
                        Save to portfolio
                      </Button>
                    )}
                    <Button
                      className={classes.pushRight}
                      variant="outlined"
                      onClick={() => this.setState({ colorDialogIsOpen: true })}
                    >
                      Configure Chart Colors
                    </Button>
                    {/* <Button
                      className={classes.pushRight}
                      variant="outlined"
                      onClick={() => createSvg(config, `${slugify(localPortfolio.name)}.svg`)}
                    >
                      Save as SVG
                    </Button> */}
                    <Button
                      variant="outlined"
                      className={classes.pushRight}
                      onClick={() =>
                        download(
                          this.chartRef.current.chartInstance.toBase64Image(),
                          `${slugify(localPortfolio.name)}.png`,
                        )
                      }
                    >
                      Save as PNG
                    </Button>
                  </Box>
                  <Line ref={this.chartRef} {...config} />
                  <Box display="flex" className={classes.scaleGrid}>
                    <Box display="flex" flexGrow="1" alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={!notSubtracted}
                        inputProps={{ 'aria-label': 'checkbox' }}
                        onChange={this.handleSubtractedChange}
                      />
                      <p>Background Subtracted</p>
                    </Box>
                    <Box display="flex" flexGrow="1" alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={scale.autoScaleY}
                        inputProps={{ 'aria-label': 'checkbox' }}
                        name="autoScaleY"
                        onChange={({ target: { name, checked } }) =>
                          this.handleScaleChange({ target: { name, value: checked } })
                        }
                      />
                      <div>Auto Scale Y</div>
                      <TextField
                        style={{ width: '125px' }}
                        disabled={scale.autoScaleY}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ max: scale.yMax, step: 1 }}
                        label="Y Min"
                        name="yMin"
                        onChange={this.handleScaleChange}
                        size="small"
                        type="number"
                        value={scale.yMin}
                        variant="outlined"
                      />
                      <TextField
                        style={{ width: '125px' }}
                        disabled={scale.autoScaleY}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: scale.yMin, step: 1 }}
                        label="Y Max"
                        name="yMax"
                        onChange={this.handleScaleChange}
                        size="small"
                        type="number"
                        value={scale.yMax}
                        variant="outlined"
                      />
                    </Box>
                    <Box display="flex" flexGrow="1" alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={scale.autoScaleX}
                        inputProps={{ 'aria-label': 'checkbox' }}
                        name="autoScaleX"
                        onChange={({ target: { name, checked } }) =>
                          this.handleScaleChange({ target: { name, value: checked } })
                        }
                      />
                      <div>Auto Scale X</div>
                      <TextField
                        style={{ width: '125px' }}
                        disabled={scale.autoScaleX}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ max: scale.xMax, step: 1 }}
                        label="X Min"
                        name="xMin"
                        onChange={this.handleScaleChange}
                        size="small"
                        type="number"
                        value={scale.xMin}
                        variant="outlined"
                      />
                      <TextField
                        style={{ width: '125px' }}
                        disabled={scale.autoScaleX}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: scale.xMin, step: 1 }}
                        label="X Max"
                        name="xMax"
                        onChange={this.handleScaleChange}
                        size="small"
                        type="number"
                        value={scale.xMax}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            </Box>
          </Paper>
          <AutoQuantTable
            data={autoQuantData}
            intensity={intensity}
            performance={performance}
            quality={quality}
            tests={tests}
            portfolio={localPortfolio}
          />
          {/* <div className={cl(classes['flex'], classes['flex-align-items-center'])}>
              <TextField
                className={cl(classes['m-1'], classes['w-175px'])}
                id="quality"
                label="Quality Threshold"
                variant="outlined"
                value={quality}
                size="small"
                type="number"
                onChange={this.handleThresholdChange}
              />
              <TextField
                className={cl(classes['m-1'], classes['w-175px'])}
                id="intensity"
                label="Intensity Threshold"
                variant="outlined"
                value={intensity}
                size="small"
                type="number"
                onChange={this.handleThresholdChange}
              />
              <TextField
                className={cl(classes['m-1'], classes['w-175px'])}
                id="performance"
                label="Performance Threshold"
                variant="outlined"
                value={performance}
                size="small"
                type="number"
                onChange={this.handleThresholdChange}
              />
            </div> */}
        </Page>
        {colorDialogIsOpen && (
          <ColorsDialog open={colorDialogIsOpen} onClose={this.closeColorDialog} portfolio={localPortfolio} />
        )}
      </>
    )
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { portfolioId },
    },
  },
) => {
  const portfolio =
    state.portfolios.get && state.portfolios.get._id === portfolioId && state.portfolios.get.isWithData
      ? state.portfolios.get
      : null
  const [xTicks, autoQuantData] = portfolio ? extractData(portfolio, portfolio.notSubtracted ? 'y' : 'yAdj') : [0, []]
  return {
    autoQuantData,
    xTicks,
    portfolioId,
    portfolio,
    fetchedAt: state.portfolios.fetchedAt,
    loading: isPending(state),
    hasAdminRights: isAdmin(state),
    email: getEmail(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Pass original dispatch so we can use other unrelated actions
    dispatch,
    loadPortfolio: () =>
      dispatch(services.portfolios.get(ownProps.match.params.portfolioId, { query: { withData: true } })),
  }
}

export default compose(
  withStyles(curveMetricsPageStyles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CurveMetrics)
