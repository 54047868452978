import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import ShowGraph from '@material-ui/icons/Timeline'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'

import { portfolioColumns } from '../components/ColumnAddons'
import { getPortfolio } from '../store/selectors/portfolios.selectors'
import AddEditAnnotation from '../components/AddEditAnnotation'
import DataTable from '../components/Table/DataTable'
import Page from '../components/Page'
import { services, actions } from '../store/feathers'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'
import { getTests } from '../store/selectors/tests.selectors'

import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import EditCommentIcon from '../assets/svgIcons/comment-edit.svg'
import AddCommentIcon from '../assets/svgIcons/comment-plus.svg'

const useActionStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

const SelectedActions = ({ selected = [], deSelectAll }) => {
  const classes = useActionStyles()
  const dispatch = useDispatch()
  const portfolio = useSelector(getPortfolio)
  const tests = useSelector(getTests)
  const history = useHistory()

  const addToPortfolio = (event) => {
    dispatch(
      services.portfolios.patch(portfolio._id, {
        $push: {
          tests: {
            $each: selected.map((UUID) => ({ UUID, isMasked: false, channels: [] })),
          },
        },
      }),
    ).then(() => {
      if (event) {
        dispatch(actions.tests.forceReload())
        deSelectAll()
      } else {
        history.push(`/portfolios/${portfolio._id}`)
      }
    })
  }

  return [
    <Tooltip title="Add tests and go back to portfolio page" key="1">
      <div>
        <Button
          className={classes.noWrap}
          variant="outlined"
          color="inherit"
          onClick={() => addToPortfolio()}
          startIcon={<AddBoxIcon />}
        >
          Add and close
        </Button>
      </div>
    </Tooltip>,
    <Tooltip title="Add tests" key="2">
      <div>
        <Button
          className={classes.noWrap}
          variant="outlined"
          color="inherit"
          onClick={addToPortfolio}
          startIcon={<AddBoxIcon />}
        >
          Add tests
        </Button>
      </div>
    </Tooltip>,
  ]
}

function AddTests(props) {
  const {
    match: {
      params: { portfolioId },
    },
  } = props
  const dispatch = useDispatch()
  const currentPortfolio = useSelector(getPortfolio)
  const hasAdminRights = useSelector(isAdmin)
  const email = useSelector(getEmail)
  const history = useHistory()
  const columns = portfolioColumns()
  const [editAnnotation, setEditAnnotation] = React.useState(false)

  // Inject annotation callback that is run from this closure and therefore inherits local variables on each render
  columns.find(({ field }) => field === 'annotation-action').render = (record) => {
    const Icon = record?.annotation ? EditCommentIcon : AddCommentIcon
    return (
      <IconButton edge="end" aria-label="delete" onClick={() => setEditAnnotation(record)}>
        <SvgIcon component={Icon} viewBox="0 0 24 24" />
      </IconButton>
    )
  }

  const updateAnnotation = (content) => {
    dispatch(services.tests.patch(editAnnotation.UUID, { annotation: content })).then(() => {
      setEditAnnotation(false)
    })
  }

  // Make sure portfolio is loaded
  React.useEffect(() => {
    if (!currentPortfolio) dispatch(services.portfolios.get(portfolioId))
  }, [])

  return (
    <Page
      title={currentPortfolio?.name}
      subtitle="Add tests to:"
      backTo={{ url: `/portfolios/${currentPortfolio?._id}` }}
      loadingSelector={(state) => state.tests.find === null}
      actions={[
        {
          title: 'View Portfolio',
          icon: <AssignmentIcon />,
          onClick: () => history.push(`/portfolios/${portfolioId}`),
        },
        {
          title: 'Curve Metrics',
          icon: <ShowGraph />,
          onClick: () => history.push(`/portfolios/${portfolioId}/show-curve`),
        },
      ]}
    >
      {currentPortfolio && (
        <>
          <DataTable
            columns={columns}
            selectable={hasAdminRights || email === currentPortfolio.creator || currentPortfolio.isPublic === 'write'}
            service="tests"
            size="small"
            withFilters={true}
            persistFilters={false}
            selectedActions={SelectedActions}
            selectionDisabled={(record) => !!currentPortfolio.tests.find((t) => t.UUID === record.UUID)}
            hasSearch={false}
            showFiltersButton={false}
          />
          <AddEditAnnotation
            open={!!editAnnotation}
            onSave={updateAnnotation}
            onClose={() => setEditAnnotation(null)}
            annotation={editAnnotation?.annotation}
          />
        </>
      )}
    </Page>
  )
}

export default AddTests
