import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import SaveIcon from '@material-ui/icons/SaveAlt'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import { StaticDataTable } from '../components/Table/StaticDataTable'
import { round } from '../utils/number'
import { download, portfolioToCsv, autoQuantToCsv, autoQuantToPdf } from '../utils/dataExport'
import { getProjectInfo } from '../store/selectors/project.selectors'
import { slugify } from '../utils/string'
import Spacer from './Spacer'

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 'calc(100vw - 50px)',
  },
  tableContainer: {
    overflow: 'auto !important',
    '& th': {
      whiteSpace: 'break-spaces',
    },
  },
}))

const columns = [
  {
    label: 'Index',
    field: 'index',
    hasFilter: true,
    isSortable: true,
    type: 'number',
  },
  {
    label: 'Cartridge S/N',
    field: 'cartridgeSerialNumber',
    hasFilter: true,
    isSortable: true,
    type: 'number',
  },
  {
    label: 'Cartridge Lot',
    field: 'cartridgeLot',
    hasFilter: true,
    isSortable: true,
    type: 'number',
  },
  {
    label: 'Sample Info',
    field: 'sampleInfo1Value',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Chamber',
    field: 'chamber',
    hasFilter: true,
    isSortable: true,
    type: 'number',
  },
  {
    label: 'Channel',
    field: 'channel',
    hasFilter: true,
    isSortable: true,
    type: 'number',
  },
  {
    label: 'CQ Reported',
    // This field doesn't exist but we need to pass some virtual name as `field` is used as a key
    field: 'cq-reported',
    hasFilter: true,
    isSortable: true,
    type: 'number',
    calcFnc: (record) => round(record.cq).toFixed(2),
    render: (record) => <div title={record.cq}> {round(record.cq).toFixed(2)}</div>,
    customFilter: (term, record) => round(record.cq).toFixed(2).toString().includes(term),
  },
  {
    label: 'CQ Computed',
    field: 'cq-computed',
    hasFilter: true,
    isSortable: true,
    type: 'string',
    calcFnc: (record) => {
      return record.logic === 'POS' ? round(record.cq).toFixed(2) : 'N/A'
    },
    render: (record) => {
      return record.logic === 'POS' ? (
        <div title={record.cq}>{round(record.cq).toFixed(2)}</div>
      ) : (
        <div title={record.cq}>N/A</div>
      )
    },
    customFilter: (term, record) => {
      return record.logic === 'POS'
        ? round(record.cq).toFixed(2).toString().includes(term)
        : term.toLowerCase() === 'n/a'
    },
    customSort: (a, b) => {
      // Both negative or unknown - skip sorting
      if (a.logic === b.logic && a.logic !== 'POS') {
        return 0
      } else if (a.logic === 'POS' && b.logic !== 'POS') {
        // a is positive and b N/A
        return -1
      } else if (b.logic === 'POS' && a.logic !== 'POS') {
        // a is s N/A and b is POS
        return 1
      }
      return a.cq - b.cq
    },
  },
  {
    label: 'Logic',
    field: 'logic',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Qualitative Result',
    field: 'qualitativeResult',
    hasFilter: true,
    isSortable: true,
    type: 'string',
    render: (record) => record.qualitativeResult || 'Unknown',
  },
  {
    label: 'Intensity',
    field: 'intensity',
    hasFilter: true,
    isSortable: true,
    type: 'number',
    calcFnc: (record) => round(record.intensity).toFixed(2),
    render: (record) => <div title={record.intensity}>{round(record.intensity).toFixed(2)}</div>,
    customFilter: (term, record) => round(record.intensity).toFixed(2).toString().includes(term),
  },
  {
    label: 'Quality',
    field: 'quality',
    hasFilter: true,
    isSortable: true,
    type: 'number',
    calcFnc: (record) => round(record.quality).toFixed(2),
    render: (record) => <div title={record.quality}>{round(record.quality).toFixed(2)}</div>,
    customFilter: (term, record) => round(record.quality).toFixed(2).toString().includes(term),
  },
  {
    label: 'Performance',
    field: 'performance',
    hasFilter: true,
    isSortable: true,
    type: 'number',
    calcFnc: (record) => round(record.performance).toFixed(2),
    render: (record) => <div title={record.performance}>{round(record.performance).toFixed(2)}</div>,
    customFilter: (term, record) => round(record.performance).toFixed(2).toString().includes(term),
  },
  {
    label: 'Annotation',
    field: 'annotation',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Assay Name',
    field: 'assayShortName',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Assay ID',
    field: 'aaassayId',
    hasFilter: true,
    isSortable: true,
    type: 'string',
    calcFnc: (record) =>
      record.assayId
        ? record.assayId + (record.assayRevision ? parseFloat(`0.${record.assayRevision}`) : 0)
        : 'Unknown',
    render: (record) =>
      record.assayId ? `${record.assayId}${record.assayRevision ? `.${record.assayRevision}` : ''}` : 'Unknown',
  },
  {
    label: 'Instrument ID',
    field: 'instrumentId',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Operator',
    field: 'operator',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
  {
    label: 'Run ID',
    field: 'UUID',
    hasFilter: true,
    isSortable: true,
    type: 'string',
  },
]

const ExportMenu = ({ data, columns, tests, portfolio }) => {
  const projectInfo = useSelector(getProjectInfo)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const exportPdf = () => {
    autoQuantToPdf(data, columns, portfolio.name, projectInfo, `${slugify(portfolio.name)}.pdf`)
    setAnchorEl(null)
  }
  const exportAbi = () => {
    download(portfolioToCsv(tests, portfolio, projectInfo), `${slugify(portfolio.name)}-ABI.csv`)
    setAnchorEl(null)
  }
  const exportCsv = () => {
    download(autoQuantToCsv(data, columns, projectInfo), `${slugify(portfolio.name)}.csv`)
    setAnchorEl(null)
  }

  return (
    <span>
      <Tooltip title={'Export'}>
        <Button color="inherit" onClick={handleClick} aria-label={'Export'} startIcon={<SaveIcon />} variant="outlined">
          Export
        </Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={exportCsv}>Export as CSV</MenuItem>
        <MenuItem onClick={exportPdf}>Export as PDF</MenuItem>
        <MenuItem onClick={exportAbi}>Export as ABI</MenuItem>
      </Menu>
    </span>
  )
}

const AutoQuantTable = ({ children, tests, portfolio, data, onExport }) => {
  const classes = useStyles()
  const customFilters = {}
  const customSorts = {}
  const [featchedAt, setFeatchedAt] = React.useState(new Date().getTime())

  columns.forEach(({ field, customFilter, customSort }) => {
    if (customFilter) customFilters[field] = customFilter
    if (customSort) customSorts[field] = customSort
  })

  React.useEffect(() => {
    setFeatchedAt(new Date().getTime())
  }, [data])

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="row" padding="8px 16px" alignItems="center">
        <Typography variant="h6" id="tableTitle" component="div" key="title">
          AutoQuant Metrics
        </Typography>
        <Spacer />
        {children}
        <ExportMenu data={data} columns={columns} tests={tests} portfolio={portfolio} />
      </Box>
      <StaticDataTable
        key={featchedAt}
        classes={classes}
        columns={columns}
        customFilters={customFilters}
        customSort={customSorts}
        data={data}
        disableContainer={true}
        fetchedAt={featchedAt}
        hasSearch={false}
        selectable={false}
        showFiltersButton={false}
        showToolbar={false}
        size="small"
        skip={0}
        sort={0}
        // title="AutoQuant Metrics"
        // toolbarActions={toolbarActions}
        total={data.length}
        withFilters={true}
      />
    </Paper>
  )
}

export default React.memo(AutoQuantTable)
